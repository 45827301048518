























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ITableHotelData from '@/modules/cluster/interfaces/table-hotel.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

@Component({
    filters: {
        PercentFilter,
        PriceFilter,
    },
})
export default class HotelsColumn extends Vue {
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(AuthServiceS) authService!: AuthService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(RatesFiltersServiceS) ratesFiltersService!: RatesFiltersService;

    @Prop({
        type: Array,
        required: true,
    })
    hotels!: ITableHotelData[] | null;

    @Prop({
        type: Number,
        required: false,
    })
    hotelId!: number | null;

    isExpanded: boolean = false;

    get clickedHotel() {
        return this.hotelId;
    }

    get competitors() {
        return this.clusterRatesService.storeState.hotels;
    }

    isNoData(hotelId: number) {
        return this.clusterRatesService
            .isWholeHotelNoData(hotelId);
    }

    isShow(hotelId: number) {
        return hotelId === this.clickedHotel;
    }

    handleClick(hotelId: number, index: number) {
        if (this.isNoData(hotelId)) return;

        this.$emit('click-row', { hotelId, index });
    }

    getCompsetId(item: ITableHotelData) {
        const { ratesCompsetMain } = item;
        if (!ratesCompsetMain && this.clusterService.currentProvider) {
            const compset = item.compsets.find(x => x.rateProviders.includes(this.clusterService.currentProvider as string));
            return compset ? compset.id : null;
        }
        return ratesCompsetMain.id;
    }

    scoreDiff(newTotalScore: number) {
        const score = Math.round(newTotalScore);
        return `${score > 0 ? '+' : ''}${score}`;
    }

    async goToHotel(hotelId: number, compsetId: string) {
        this.clusterRatesService.setActivePage('table');
        this.ratesService.saveDocument(null);
        this.documentFiltersService.savePos(null);
        this.documentFiltersService.compsetIdFromHighLevel = compsetId;
        this.ratesFiltersService.providerFromHighLevel = this.clusterService.currentProvider;
        await this.$router.push({
            name: `${this.$route.name!.replace('.table', '')}.hotel`,
            params: {
                hotelId: String(hotelId),
            },
        });
    }
}
