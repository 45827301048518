



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import Day from '@/modules/common/types/day.type';
import PriceData from '@/modules/cluster/components/rates/table/price-data.vue';
import RatesClusterTableTooltip from '@/modules/cluster/components/rates/table/table-tooltip.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';

import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';

import moment from 'moment';
import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';

@Component({
    components: { PriceData, RatesClusterTableTooltip },
    filters: {
        PercentFilter,
        PriceFilter,
        WeekdayLetter,
        DateFilter: (value: (Date | null)) => {
            if (!value) return '';
            return moment(value).format('DD/MM');
        },
    },
})
export default class DayPricesColumn extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;

    @Prop({
        type: Number,
        required: true,
    })
    day!: Day;

    @Prop({
        type: Array,
        required: true,
    })
    prices!: IPriceData[];

    @Prop({
        type: Number,
        required: false,
    })
    hotelId!: number | null;

    @Prop({
        type: Number,
        required: false,
    })
    indexTab!: number;

    private activeHotelId: number | null = null;
    private tooltipTop: number | null = null;
    private isVisible: boolean = false;

    get clickedHotel() {
        return this.hotelId;
    }

    get isLastDayOfMonth() {
        const { lastDayCurrentMonth } = this.documentFiltersService;
        return lastDayCurrentMonth === this.day;
    }

    get date() {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, this.day);
    }

    get isPast() {
        return this.documentFiltersService.isPreviousDay(this.day);
    }

    get isMonday() {
        return this.date.getDay() === 1;
    }

    get isSunday() {
        return this.date.getDay() === 0;
    }

    get isToday() {
        return this.documentFiltersService.isCurrentDay(this.day);
    }

    mounted() {
        if (this.isToday) {
            setTimeout(() => this.showInViewport());
        }
    }

    showInViewport() {
        if (!this.$parent) {
            return;
        }

        const { container } = this.$refs as { container: HTMLDivElement };
        const { wrapper } = this.$parent.$refs as { wrapper: HTMLDivElement };

        if (!container || !wrapper) return;

        const { width } = wrapper.getBoundingClientRect();
        const targetScroll = container.getBoundingClientRect().left - width / 2;

        wrapper.scrollLeft += targetScroll;
    }

    handleClick(hotelId: number, index: number) {
        this.$emit('click-row', { hotelId, index });
    }

    isShow(hotelId: number) {
        return hotelId === this.clickedHotel;
    }

    onHoverPrices(data: { cellIndex: number, hotelId: number, componentContainer: HTMLDivElement } | false) {
        if (!data) {
            this.activeHotelId = null;
            this.tooltipTop = null;
            return;
        }

        const { cellIndex, hotelId, componentContainer } = data;
        const cell = (this.$refs.priceCell as HTMLDivElement[])[cellIndex];

        if (cell && this.day) {
            if (!hotelId) {
                return;
            }

            const isNoData = this.clusterRatesService
                .isNoData(this.day, hotelId);

            if (this.activeHotelId !== hotelId && !isNoData) {
                this.activeHotelId = hotelId;
                this.tooltipTop = cell.offsetTop + componentContainer.offsetTop + componentContainer.offsetHeight;
            }
        } else {
            this.activeHotelId = null;
        }
    }

    getCompetitors(hotelId: number) {
        return this.clusterRatesService.getCompetitors(hotelId) || [];
    }

    currency(hotelId: number) {
        const settings = this.clusterRatesService.getSettings(hotelId);

        if (!settings) {
            return 0;
        }

        const mainCompsetData = this.clusterService.getMainCompsetData(hotelId) as RatesCompsetMainModel;
        const currency = this.ratesCommonService.currency(mainCompsetData);

        return this.helperService.currencySymbol(currency);
    }

    getPrice(hotelId: number, competitorId?: number) {
        const { day } = this;
        const priceData: IPriceData = {
            isNoData: false,
            isNa: false,
            isSoldOut: false,
            competitionPercent: null,
            price: null,
            currency: null,
            isCompetitor: true,
            hotelId,
            compsetId: '',
        };

        const mainCompsetData = this.clusterService
            .getMainCompsetData(hotelId) as RatesCompsetMainModel;

        if (this.clusterRatesService.isNoData(day, hotelId)) {
            priceData.isNoData = true;

            return priceData;
        }

        if (this.clusterRatesService.isNa(day, hotelId, competitorId)) {
            priceData.isNa = true;

            return priceData;
        }

        if (this.clusterRatesService.isSoldOut(day, hotelId, competitorId)) {
            priceData.isSoldOut = true;

            return priceData;
        }

        if (mainCompsetData) {
            priceData.price = this.clusterRatesService
                .getPrice(this.day, hotelId, competitorId);
            priceData.competitionPercent = this.clusterRatesService
                .getCompetitionPercent(this.day, hotelId);
            priceData.currency = this.ratesCommonService
                .currency(mainCompsetData);
            priceData.color = this.clusterRatesService
                .getColor(this.day, hotelId);

            return priceData;
        }

        return priceData;
    }

    async openDailyPopup(index: number) {
        const price = this.prices[index];

        if (!price.hotelId) return;

        this.clusterRatesService.switchCurrentHotel(price.hotelId!);
        this.clusterRatesService.setCurrentRatesClusterData();

        await this.$router.push({
            name: `${this.$route.name!}.day-rate`,
            params: {
                day: String(this.day),
                hotelId: String(price.hotelId),
                compsetId: String(price.compsetId),
            },
        });
    }
}
