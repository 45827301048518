
















































































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';
import Day from '@/modules/common/types/day.type';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import ITableHotelData from '@/modules/cluster/interfaces/table-hotel.interface';
import DayPricesColumn from '@/modules/cluster/components/rates/table/day-prices-column.vue';
import HotelsColumn from '@/modules/cluster/components/rates/table/hotels-column.vue';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

const SCROLL_SPEED = 35;

@Component({
    components: {
        HotelsColumn,
        DayPricesColumn,
        DotsSpinner,
    },
    filters: {
        DateFilter: (value: Date | null) => {
            if (!value) return '';
            return moment(value).format('DD/MM/YY');
        },
    },
})
export default class RatesClusterTable extends Vue {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(HotelsServiceS) hotelsService!: HotelsService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(ClusterCompsetsServiceS) clusterCompsetsService!: ClusterCompsetsService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;

    private scrollLeft: boolean | any = false;
    private clickedHotel: number = -1;
    private hasScroll: boolean = false;
    private openIndex: number = 0;

    private isDragScrolling = false;

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);

        this.clusterRatesService.onProviderChange(() => {
            const wrapper = this.$refs.wrapper as HTMLElement;
            if (wrapper) {
                wrapper.scrollTop = 0;
            }
        });
    }

    handleResize = () => {
        Vue.nextTick(() => {
            const wrapper = this.$refs.wrapper as HTMLElement;
            this.hasScroll = wrapper.scrollHeight > wrapper.clientHeight;
        });
    };

    clickRowHandle(data: { hotelId: number; index: number }) {
        if (this.isDragScrolling) return;

        if (this.clickedHotel !== data.hotelId) {
            this.clusterService.getClusterHotels(data.hotelId);
        }
        this.clickedHotel = data.hotelId === this.hotelId ? -1 : data.hotelId;
        this.openIndex = data.index === this.indexTab ? 1 : data.index;
    }

    get hotelId() {
        return this.clickedHotel;
    }

    get indexTab() {
        return this.openIndex;
    }

    updated() {
        Vue.nextTick(() => {
            const wrapper = this.$refs.wrapper as HTMLElement;
            this.hasScroll = wrapper.scrollHeight > wrapper.clientHeight;
        });
    }

    get isScrollVisible() {
        return this.hasScroll;
    }

    handleScrollMouseRight(e: MouseEvent) {
        if (e.button !== 0) {
            return;
        }
        this.moveLeft(SCROLL_SPEED);
    }

    handleScrollMouseLeft(e: MouseEvent) {
        if (e.button !== 0) {
            return;
        }
        this.moveLeft(-SCROLL_SPEED);
    }

    handleTouchRight() {
        this.moveLeft(SCROLL_SPEED);
    }

    handleTouchLeft() {
        this.moveLeft(-SCROLL_SPEED);
    }

    moveLeft(way: number) {
        const wrapper = this.$refs.wrapper as HTMLElement;
        if (!this.scrollLeft) {
        // eslint-disable-next-line no-return-assign
            this.scrollLeft = setInterval(() => (wrapper.scrollLeft += way), 30);
        }
    }

    handleStop() {
        clearInterval(this.scrollLeft);
        this.scrollLeft = false;
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    handleScroll(e: Event) {
        const targetElement = e.target as HTMLElement;
        const minDistanceToBottom = 10;
        const scrollMax = Math.max(
            targetElement.scrollHeight,
            targetElement.offsetHeight,
            targetElement.clientHeight,
        );

        const scrollCurrent = Math.round(
            window.innerHeight
        + Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            targetElement.scrollTop,
        ),
        );
        const isBottomOfWindow = scrollMax - scrollCurrent < 0
        || scrollMax - scrollCurrent <= minDistanceToBottom;

        if (isBottomOfWindow) {
            this.clusterService.loadMoreData('rates');
        }
    }

    startDragScroll() {
        this.isDragScrolling = true;
        const moveHandler = (moveEvent: MouseEvent) => {
            const { wrapper } = this.$refs as { wrapper: HTMLDivElement };
            wrapper.scrollLeft -= moveEvent.movementX;
        };

        window.addEventListener('mousemove', moveHandler);
        window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', moveHandler);
            this.isDragScrolling = false;
        }, { once: true });
    }

    get currentDay() {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const date = new Date();
        if (year === date.getFullYear() && month === date.getMonth()) {
            return date.getDate() - 1;
        }
        if (year > date.getFullYear() || month < date.getMonth()) {
            return 32;
        }
        return -1;
    }

    get days() {
        return this.documentFiltersService.days;
    }

    get month() {
        return this.documentFiltersService.month;
    }

    get year() {
        return this.documentFiltersService.year;
    }

    get hotels() {
        const { hotels } = this.clusterRatesService;

        if (!hotels) {
            return null;
        }

        return hotels
            .map(hotel => {
                const hotelName = hotel.hotelName
                || this.hotelsService.getHotelName(hotel.hotelId)
                || hotel.hotelId;

                return {
                    id: hotel.hotelId,
                    name: hotelName,
                    isCompetitor: false,
                    newTotalScore: hotel.newTotalScore,
                    totalScore: hotel.totalScore,
                    ratesCompsetMain: hotel.compsetMain as RatesCompsetMainModel,
                    compsets: hotel.compsets,
                } as ITableHotelData;
            });
    }

    isWholeHotelNoData(hotelId: number) {
        return this.clusterRatesService
            .isWholeHotelNoData(hotelId);
    }

    getPrices(day: Day) {
        if (!this.hotels) {
            return [];
        }
        return this.hotels.map(hotel => this.getPrice(hotel.id, day));
    }

    getPrice(hotelId: number, day: Day) {
        const priceData: IPriceData = {
            isNoData: false,
            isNa: false,
            isSoldOut: false,
            competitionPercent: null,
            price: null,
            currency: null,
            hotelId,
            compsetId: '',
        };

        const mainCompsetData = this.clusterService
            .getMainCompsetData(hotelId) as RatesCompsetMainModel;

        if (mainCompsetData) {
            priceData.price = this.clusterRatesService
                .getPrice(day, hotelId);
            priceData.currency = this.ratesCommonService
                .currency(mainCompsetData);
            priceData.competitionPercent = this.clusterRatesService
                .getCompetitionPercent(day, hotelId);
            priceData.color = this.clusterRatesService
                .getColor(day, hotelId);
            priceData.compsetId = mainCompsetData.id;
        }

        if (this.clusterRatesService.isNoData(day, hotelId)) {
            priceData.isNoData = true;
            return priceData;
        }

        if (this.clusterRatesService.isNa(day, hotelId)) {
            priceData.isNa = true;
            return priceData;
        }

        if (this.clusterRatesService.isSoldOut(day, hotelId)) {
            priceData.isSoldOut = true;
            return priceData;
        }

        return priceData;
    }

    get isRateAscendingSort() {
        return this.clusterRatesService.storeState.ratesSorting === -1;
    }

    get isHotelNameAscendingSort() {
        return this.clusterRatesService.storeState.hotelNameSorting === -1;
    }

    get isRateSortingActive() {
        return !!this.clusterRatesService.storeState.ratesSorting;
    }

    sort() {
        this.clusterRatesService.toggleScoreSort();
    }

    sortByABC() {
        this.clusterRatesService.toggleHotelNameSort();
    }
}
