



























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Day from '@/modules/common/types/day.type';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';

import Currency from '@/modules/common/components/currency.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';

@Component({
    filters: {
        PercentFilter,
        PriceFilter,
        DateFilter: (value : (Date |null)) => {
            if (!value) return '';
            return moment(value).format('MMM DD, YYYY');
        },
    },
    components: { Currency },
})

export default class RatesClusterTableTooltip extends Vue {
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterRatesServiceS) clusterRatesService!: ClusterRatesService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;

    @Prop({
        type: Number,
        required: true,
    })
    day!: Day;

    @Prop({
        type: Number,
        required: true,
    })
    hotelId!: number;

    @Prop({
        type: Number,
        required: true,
    })
    top!: number;

    get price() {
        const { hotelId, day } = this;
        const mainCompsetData = this.clusterService.getMainCompsetData(hotelId) as RatesCompsetMainModel;

        const priceData: IPriceData = {
            isNoData: false,
            isNa: false,
            isSoldOut: false,
            competitionPercent: null,
            price: null,
            currency: null,
        };

        if (this.clusterRatesService.isNoData(day, hotelId)) {
            priceData.isNoData = true;

            return priceData;
        }

        if (this.clusterRatesService.isNa(day, hotelId)) {
            priceData.isNa = true;

            return priceData;
        }

        if (this.clusterRatesService.isSoldOut(day, hotelId)) {
            priceData.isSoldOut = true;

            return priceData;
        }

        if (mainCompsetData) {
            priceData.price = this.clusterRatesService
                .getPrice(day, hotelId);
            priceData.competitionPercent = this.clusterRatesService
                .getCompetitionPercent(day, hotelId);
            priceData.currency = this.ratesCommonService
                .currency(mainCompsetData);
            priceData.color = this.clusterRatesService
                .getColor(day, hotelId);
        }

        return priceData;
    }

    get isNa() {
        return this.clusterRatesService
            .isNa(this.day, this.hotelId);
    }

    get date() {
        const { year, month } = this.documentFiltersService;
        return new Date(year, month, this.day);
    }

    get offset() {
        return {
            top: `${this.top}px`,
        };
    }
}
