





































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import IPriceData from '@/modules/cluster/interfaces/price-data.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import Day from '@/modules/common/types/day.type';
import Month from '@/modules/common/types/month.type';
import Year from '@/modules/common/types/year.type';

@Component({
    filters: { PercentFilter, PriceFilter },
})
export default class PriceData extends Vue {
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(EventsManagerServiceS) eventsManagerService!: EventsManagerService;

    @Prop({
        type: Object,
        required: true,
    })
    priceData!: IPriceData | null;

    @Prop({
        type: Boolean,
        required: true,
    })
    hasBorder!: boolean;

    @Prop({
        type: Date,
        required: false,
    })
    date!: Date;

    @Prop({
        type: Number,
    })
    cellIndex!: number;

    get hasHolidayEvents() {
        return this.date && this.eventsManagerService.hasHolidayEventsByDay({
            day: this.date.getDate() as Day,
            month: this.date.getMonth() as Month,
            year: this.date.getFullYear() as Year,
        });
    }

    get hasLocalEvents() {
        return this.date && this.eventsManagerService.hasLocalEventsByDay({
            day: this.date.getDate() as Day,
            month: this.date.getMonth() as Month,
            year: this.date.getFullYear() as Year,
        });
    }

    onHover() {
        if (!this.priceData) return;

        const { hotelId } = this.priceData!;
        const { priceCell: componentContainer } = this.$refs;
        const { cellIndex } = this;
        this.$emit('hover', { cellIndex, hotelId, componentContainer });
    }

    onOut() {
        this.$emit('hover', false);
    }
}
